import React, { useState } from 'react'
import clsx from 'clsx'
import * as styles from './Input.module.scss'

const Input = ({
  onSubmit = () => {}
}) => {
  const [ value, setValue ] = useState('')
  const className = clsx(
    styles.Input
  )
  const handleChange = (e) => {
    e.preventDefault()
    setValue(e.target.value)
  }
	return (
    <form>
      <label>
        Guess:
        <input 
          type="text"
          name="guess"
          value={value}
          onChange={handleChange}
        />
      </label>
      <input type="submit" value="Submit" onClick={(e) => onSubmit(e, value)} />
    </form>
  )
}

export default Input